

















import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { mapGetters } from "vuex";
import { AUTH_LOADING } from "@/store/modules/auth/constants";

export default Vue.extend({
  name: "SubmitButton",
  computed: {
    ...mapGetters("auth", {
      get_auth_loading: AUTH_LOADING
    })
  },
  props: {
    btn_text: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    disable: {
      type: Boolean,
      required: true
    }
  }
});
